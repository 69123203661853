import { getRequestClient } from "@repo/client";
import {
  createResource,
  createSignal,
  ErrorBoundary,
  For,
  Match,
  Show,
  Suspense,
  Switch,
  type Component,
} from "solid-js";
import { useWire } from "@core/wire";
import styles from "./ShareThreadModal.module.css";
import { Spinner } from "@core/components/_original/Spinner";
import {
  TbBrandFacebook,
  TbBrandLinkedin,
  TbBrandReddit,
  TbClipboardCheck,
  TbClipboardCopy,
  TbRefresh,
} from "solid-icons/tb";
import { PrimaryCTA } from "@core/components/cta/PrimaryCTA";
import { IconCTA } from "@core/components/cta/IconCTA";
import { StBrandX } from "@core/components/icons";
import { captureException } from "@repo/observability";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { UsersTagsList } from "@core/components/fields/UsersTagsList";

export const ShareThreadModal: Component<{
  threadId: string;
  messageId?: string;
  type: "link" | "email";
  onClose?: () => void;
}> = (props) => {
  return (
    <div>
      <Show when={props.type === "link"} fallback={<ShareThreadModalEmail {...props} />}>
        <ShareThreadModalLink {...props} />
      </Show>
    </div>
  );
};

const ShareThreadModalLink: Component<{
  threadId: string;
  messageId?: string;
  type: "link" | "email";
  onClose?: () => void;
}> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.auth.token);

  const [res, { refetch }] = createResource(
    () => [props.threadId, props.messageId] as const,
    async ([threadId, messageId]) => {
      const res = await client.controlplane.ShareThread(threadId, {
        threadId,
        messageId: messageId as string,
        shareMethod: "share_link:v1",
        shareTo: [],
      });
      return res.data;
    },
  );
  return (
    <div class={styles["share-thread-modal"]}>
      <p class={styles["share-thread-modal__title"]}>
        <span class={styles["share-thread-modal__title-text"]}>Share chat</span>
      </p>

      <Suspense
        fallback={
          <div class={styles["share-thread-modal__loading"]}>
            <Spinner class={styles["share-thread-modal__loading-spinner"]} />
            <p class={styles["share-thread-modal__loading-text"]}>Generating shareable link</p>
          </div>
        }
      >
        <ErrorBoundary
          fallback={(err) => {
            captureException(err);

            return (
              <div class={styles["share-thread-modal__error"]}>
                <p class={styles["share-thread-modal__error-title"]}>We couldn't generate a shareable link.</p>
                <p class={styles["share-thread-modal__error-subtitl"]}>
                  Click to retry. If the issue persists, please contact our support team.
                </p>

                <div>
                  <SecondaryCTA
                    accessiblePrefix="Click to "
                    icon={TbRefresh}
                    data-test-id="retry-shareable-link-btn"
                    label="Retry"
                    onClick={refetch}
                  />
                </div>
              </div>
            );
          }}
        >
          <div class={styles["share-thread-modal__created"]}>
            <p class={styles["share-thread-modal__created-text"]}>
              A public link has been created. Anyone with this link can view your chat.
            </p>
            <div class={styles["share-thread-modal__created-link"]}>
              <p class={styles["share-thread-modal__created-link-text"]}>
                {res()?.address}
                <span class={styles["share-thread-modal__created-link-text-gradient"]} />
              </p>
              <div class={styles["share-thread-modal__created-link-copy"]}>
                <CopyLinkButton toCopy={res()?.address ?? ""} />
              </div>
            </div>
            <div class={styles["share-thread-modal__socials"]}>
              <For
                each={[
                  {
                    link: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(res()?.address ?? "")}`,
                    icon: TbBrandLinkedin,
                    name: "LinkedIn",
                  },
                  {
                    link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(res()?.address ?? "")}`,
                    icon: TbBrandFacebook,
                    name: "Facebook",
                  },
                  {
                    link: `https://www.reddit.com/submit?url=${encodeURIComponent(res()?.address ?? "")}`,
                    icon: TbBrandReddit,
                    name: "Reddit",
                  },
                  {
                    link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(res()?.address ?? "")}`,
                    icon: StBrandX,
                    name: "X.com",
                  },
                ]}
              >
                {(item) => (
                  <IconCTA
                    data-test-id={`share-thread-to-${item.name}`}
                    accessibleLabel={`Click to share to ${item.name}`}
                    icon={item.icon}
                    href={item.link}
                    external
                  />
                )}
              </For>
            </div>

            <p class={styles["share-thread-modal__created-text"]}>
              Want to collaborate with others? {/* biome-ignore lint/a11y/noBlankTarget: <explanation> */}
              <a href="https://docs.storytell.ai/features/collections/collaboration" target="_blank">
                Learn more about shared Collections.
              </a>
            </p>
          </div>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

const ShareThreadModalEmail: Component<{
  threadId: string;
  messageId?: string;
  type: "link" | "email";
  onClose?: () => void;
}> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.auth.token);

  const [state, setState] = createSignal({
    loading: false,
    emails: [] as string[],
    error: false,
  });

  const onSubmitEmails = async () => {
    const emails = state().emails;
    const { threadId, messageId } = props;
    setState((s) => ({
      ...s,
      loading: true,
      error: false,
    }));

    try {
      const res = await client.controlplane.ShareThread(threadId, {
        threadId,
        messageId: messageId ?? "",
        shareMethod: "share_email:v1",
        shareTo: emails,
      });
      setState({
        loading: false,
        error: false,
        emails: [],
      });
    } catch (error) {
      captureException(error);
      setState({
        loading: false,
        error: true,
        emails: state().emails,
      });
    }
  };

  return (
    <div class={styles["share-thread-modal"]}>
      <p class={styles["share-thread-modal__title"]}>
        <span class={styles["share-thread-modal__title-text"]}>Share chat via email</span>
      </p>

      <Switch>
        <Match when={state().loading}>
          <div class={styles["share-thread-modal__loading"]}>
            <Spinner class={styles["share-thread-modal__loading-spinner"]} />
            <p class={styles["share-thread-modal__loading-text"]}>Sending links</p>
          </div>
        </Match>
        <Match when={state().error}>
          <div class={styles["share-thread-modal__error"]}>
            <p class={styles["share-thread-modal__error-title"]}>We couldn't send the links.</p>
            <p class={styles["share-thread-modal__error-subtitl"]}>
              Click to retry. If the issue persists, please contact our support team.
            </p>

            <div>
              <SecondaryCTA
                accessiblePrefix="Click to "
                icon={TbRefresh}
                data-test-id="retry-shareable-link-btn"
                label="Retry"
                onClick={onSubmitEmails}
              />
            </div>
          </div>
        </Match>
        <Match when={true}>
          <UsersTagsList
            sendInvites={onSubmitEmails}
            options={[{ value: "collection:read", label: "Viewer" }]}
            access={[]}
          />
        </Match>
      </Switch>
    </div>
  );
};

const CopyLinkButton: Component<{ toCopy: string }> = (props) => {
  const [copied, setCopied] = createSignal(false);

  const copy = () => {
    navigator.clipboard.writeText(props.toCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <PrimaryCTA
      onClick={copy}
      icon={copied() ? TbClipboardCheck : TbClipboardCopy}
      size="small"
      data-test-id="copy-link"
      accessiblePrefix="Click to "
      label={copied() ? "Copied!" : "Copy link"}
    />
  );
};
