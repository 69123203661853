import type { Root } from "remark-gfm";
import { visit } from "unist-util-visit";

export const remarkCustomExtensionsPlugin = () => {
  return (tree: Root) => {
    const sections: { name: string; start: number; end: number }[] = [];
    let currentSection: { name: string; start: number } | null = null;

    // First pass: split text nodes containing section markers
    visit(tree, "text", (node, index, parent) => {
      if (!parent) return;

      const text = node.value as string;
      const parts = text.split(/(\[\[\[.*?\]\]\]|\[\[\/.*?\]\]\])/g);

      if (parts.length > 1) {
        const newNodes = parts
          .filter(Boolean) // Remove empty strings
          .map((part) => ({
            type: "text" as const,
            value: part.trim(),
            position: node.position,
          }));

        const nodeIndex = parent.children.indexOf(node);
        parent.children.splice(nodeIndex, 1, ...newNodes);
      }
    });

    // Second pass: identify section boundaries
    visit(tree, "paragraph", (paragraphNode, paragraphIndex) => {
      if (!Array.isArray(paragraphNode.children)) return;

      paragraphNode.children.forEach((node, index) => {
        if (node.type !== "text") return;
        const text = node.value as string;

        // Match section start: [[[Section Name]]]
        const startMatch = text.match(/^\[\[\[(.*?)\]\]\]$/);
        if (startMatch && !currentSection) {
          currentSection = {
            name: startMatch[1]?.trim() ?? "",
            start: paragraphIndex!,
          };
        }

        // Match section end: [[[/Section Name]]]
        const endMatch = text.match(/^\[\[\[\/(.*?)\]\]\]$/);
        if (endMatch && currentSection && endMatch[1]?.trim() === currentSection.name) {
          sections.push({
            name: currentSection.name,
            start: currentSection.start,
            end: paragraphIndex!,
          });
          currentSection = null;
        }
      });
    });

    // Third pass: transform sections into custom nodes
    for (const section of sections.reverse()) {
      if (!tree.children) continue;

      // Get all nodes between start and end, including the paragraphs
      let sectionContent = tree.children.slice(section.start, section.end + 1);

      // Remove section markers from the first and last paragraphs
      if (sectionContent.length > 0) {
        const firstParagraph = sectionContent[0];
        const lastParagraph = sectionContent[sectionContent.length - 1];

        if (firstParagraph?.type === "paragraph") {
          firstParagraph.children = firstParagraph.children.filter(
            (child) => child.type !== "text" || !child.value.match(/^\[\[\[.*?\]\]\]$/),
          );
        }

        if (lastParagraph?.type === "paragraph") {
          lastParagraph.children = lastParagraph.children.filter(
            (child) => child.type !== "text" || !child.value.match(/^\[\[\[\/.*?\]\]\]$/),
          );
        }

        // Remove empty paragraphs
        sectionContent = sectionContent.filter((node) => node.type !== "paragraph" || node.children.length > 0);
      }

      const sectionNode = {
        type: "section",
        name: section.name,
        children: sectionContent as typeof tree.children,
        data: {
          hName: "div",
          hProperties: {
            className: ["custom-section"],
            "data-section-name": section.name,
          },
        },
      } as unknown as (typeof tree.children)[number];

      // Replace the section content with our custom node
      tree.children.splice(section.start, section.end - section.start + 1, sectionNode);
    }

    // Debug logging
    // console.log("Sections found:", sections);
    // console.log("Tree after transformation:", JSON.stringify(tree, null, 2));
  };
};

export function preprocessMarkdown(markdown: string): string {
  return markdown.replace(/\n*(\[\[\[.*?\]\]\]|\[\[\/.*?\]\]\])\n*/g, "\n\n$1\n\n");
}
