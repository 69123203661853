import { stAnalytics } from "@repo/analytics";
import type { curator } from "@repo/client";
import { type Component, For, createMemo } from "solid-js";
import { SideBarSectionAccordion } from "@core/components/SideBarSectionAccordion";
import { classNames } from "@core/lib/classNames";
import { getScreenType } from "@core/lib/ui/getScreenType";
import { StoryTileDrawerScreen } from "@core/screens/drawer/StoryTileDrawerScreen";
import { useUIState } from "@core/ui/UIState";
import styles from "./TextUnitStoryTiles.module.css";

export const TextUnitStoryTiles: Component<{ tiles: curator.Citation[] }> = (props) => {
  const ctx = useUIState();
  const [, setRightContents] = ctx.rightDrawerContents;
  const [rightOpen, setRightOpen] = ctx.rightDrawer;

  const tiles = createMemo(() => props.tiles.filter((t) => t.relevance >= 0));

  const format = (text: string) => {
    const applyPrefix = /^[a-z]/.test(text);
    const applySuffix = !text.endsWith(".") && !text.endsWith("!");
    return `${applyPrefix ? "..." : ""} ${text}${applySuffix ? "..." : ""}`;
  };

  return (
    <SideBarSectionAccordion
      label="StoryTiles™"
      depth={0}
      initiallyOpen
      modifier="chat"
      class="no-fade"
      onClick={() => {
        stAnalytics.track("click_tracking", {
          cta: "expand_storytile",
          position: "chat",
          screen: getScreenType(),
        });
      }}
    >
      <div class="w-full max-w-full overflow-x-auto -mx-2 px-2 pb-2 flex gap-3 pt-2 items-stretch">
        <For each={tiles()}>
          {(tile) => (
            <button
              onClick={() => {
                setRightContents(() => () => <StoryTileDrawerScreen tiles={tiles()} initial={tile.citationId} />);
                setRightOpen(!rightOpen());
                stAnalytics.track("click_tracking", {
                  cta: "open_storytile",
                  position: "chat",
                  screen: getScreenType(),
                });
              }}
              type="button"
              class={classNames(styles["story-tile"])}
            >
              <span class={styles["story-tile__label"]}>{format(tile.text)}</span>
            </button>
          )}
        </For>
      </div>
    </SideBarSectionAccordion>
  );
};
