import type { MessageInsert, ThreadMessage, curator } from "@repo/client";
import { useSearchParams, type RouteSectionProps } from "@solidjs/router";
import {
  type Component,
  createEffect,
  createMemo,
  createSignal,
  on,
  onCleanup,
  onMount,
  Show,
  startTransition,
} from "solid-js";
import { AlternativeSideBar } from "@core/components/AlternativeSideBar";
import { ChatTop } from "@core/components/ChatTop";
import { CollectionsTop } from "@core/components/CollectionsTop";
import { CollectionsModal } from "@core/components/containers/CollectionsModal";
import { RightDrawer } from "@core/components/containers/RightDrawer";
import { ThreadContainer } from "@core/components/containers/ThreadContainer";
import { SkipToContent } from "@core/components/cta/SkipToContent";
import { TwoColumnLayout } from "@core/components/layouts/TwoColumnLayout";
import { SEOHeaders } from "@core/components/meta/SEOHeaders";
import { PromptContextProvider } from "@core/domains/chat/prompt/PromptContext";
import type { CampaingPageDataPrompt } from "@core/marketing/useUseCasesData";
import { CustomEvents } from "@core/ui/custom-events";
import { useWire } from "@core/wire";
import { ThreadPanel } from "../components/panels/ThreadPanel/ThreadPanel";
import { useAutoSubmitFileCampaign } from "../components/useAutoSubmitFileCampaign";
import { mapThreadMessagesToUIData } from "../utils/mapThreadMessagesToUIData";
export type ThreadGroup = { type: "group"; content: ThreadMessage[] };
export type ThreadUnit = {
  type: "unit";
  content: curator.MessageKnowledgeV1 | MessageInsert;
};
export type UIManagedAsset = {
  assetId: string;
  name: string;
  active: boolean;
  justChanged: boolean;
};

export const ThreadScreen: Component<{ prompt?: CampaingPageDataPrompt } & RouteSectionProps<unknown>> = (props) => {
  const wire = useWire();
  const [params] = useSearchParams();
  const threadsService = wire.services.threads;
  const identityService = wire.services.auth;
  const collectionsService = wire.services.collections;
  const [_threads, { updateThreadLabel }] = wire.services.threads.threadsListResource;
  const threadCollections = () => threadsService.getThreadCollections(props.params.thread_id || "") || [];
  const firstCollection = () => {
    const personal = collectionsService.getPersonalRoot();
    // This is to handle prompt campaigns, default to the personal collection
    if (props.prompt) {
      return personal;
    }
    return collectionsService.getCollection(threadCollections()[0] || "") || personal;
  };

  /**
   * Threads that have been shared with a user will have a foreign collection id
   * Don't allow the user to interact with the thread in this case
   */
  const isThreadReadOnly = createMemo(() => {
    if (props.prompt) return false;

    const collectionId = wire.services.threads.snapshot.context.collection;
    const allCollections = collectionsService.getAllCollections();

    return allCollections.every((c) => c.id !== collectionId);
  });

  // Post-processing messages and grouping them in blocks
  // Using the text instruction unit as the start of a next block
  const messages = createMemo(() =>
    mapThreadMessagesToUIData({
      messages: wire.services.threads.messages(),
      threadId: wire.services.threads.snapshot.context.threadId,
      updateThreadLabel,
    }),
  );

  const isNewThreadId = createMemo(() => props.params.thread_id === "new");

  createEffect(
    on(
      () => isNewThreadId(),
      (isNew) => {
        if (isNew) {
          wire.services.limiting.guest.resetLastInteraction();
          threadsService.send(threadsService.eventFactory.newResetEvent());
        }
      },
    ),
  );

  createEffect(() => {
    if (!identityService.isReady()) return;
    if (isNewThreadId()) return;

    const threadId = threadsService.snapshot?.context?.threadId;
    const threadIdURL = props.params.thread_id;
    if (threadId !== threadIdURL && threadIdURL) {
      wire.services.limiting.guest.resetLastInteraction();
      threadsService.send(
        threadsService.eventFactory.newResumeThreadEvent(threadIdURL, params.token as string | undefined),
      );
    }
  });

  return (
    <PromptContextProvider
      initialPrompt={props.prompt?.prompt.name ? { content: props.prompt.prompt.name, highlight: true } : undefined}
      transformationID={props.prompt?.prompt.id}
      activeCollection={firstCollection}
      autoFocusInput
      hideBackdrop
      positioning="sticky bottom-0"
    >
      <SEOHeaders
        title={props.prompt?.prompt.name ? `${props.prompt.prompt.name} - AI prompt | Storytell.ai` : undefined}
        description={props.prompt?.prompt.summary}
      />
      <SkipToContent />
      <CollectionsModal accessibleLabel="Create Collection." />
      <RightDrawer accessibleLabel="" />
      <TwoColumnLayout
        toggleTop
        sidebar={<AlternativeSideBar collectionId={firstCollection()?.id} />}
        content={
          <>
            <CollectionsTop />
            <Show when={!isThreadReadOnly()}>
              <ChatTop
                messages={messages}
                breadcrumbs={collectionsService.getBreadcrumbs(firstCollection()?.id || "") || []}
              />
            </Show>
            <ThreadContainer>
              <ThreadPanel
                collectionId={firstCollection()?.id}
                isThreadReadOnly={isThreadReadOnly()}
                isNewThreadId={isNewThreadId()}
                messages={messages()}
                prompt={props.prompt}
              />
            </ThreadContainer>
            <AutoSubmit />
          </>
        }
      />
    </PromptContextProvider>
  );
};

// Needed as a component so that it can be inside the prompt context provider
const AutoSubmit = () => {
  useAutoSubmitFileCampaign();
  return undefined;
};
