import { stAnalytics } from "@repo/analytics";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { TbFolderFilled } from "solid-icons/tb";
import {
  type Accessor,
  createEffect,
  createMemo,
  createSignal,
  on,
  onCleanup,
  onMount,
  Show,
  startTransition,
  Suspense,
} from "solid-js";
import { CollectionAssetsTable } from "@core/components/CollectionAssetTable";
import { Anchor } from "@core/components/cta/Anchor";
import { PrimaryCTA } from "@core/components/cta/PrimaryCTA";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { InfoPanel } from "@core/components/InfoPanel";
import { SectionHeader } from "@core/components/SectionHeader";
import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";
import { pluralize } from "@core/lib/string/pluralize";
import { formatCollectionName } from "@core/lib/ui/formatCollectionName";
import { getScreenType } from "@core/lib/ui/getScreenType";
import { ProcessingReadableState, readableProcessingState } from "@core/lib/ui/readableProcessingState";
import { useWire } from "@core/wire";
import styles from "./AssetsDrawerScreen.module.css";
import { useIsIdentityConnecting } from "@core/domains/identity/hooks/useIsIdentityConnecting";
import { createListResourceOptions } from "@core/lib/api/createListResourceOptions";
import { parseAssetsListResponse } from "@core/lib/api/parseAssetListResponse";
import { Pagination } from "@core/components/Pagination";

interface Props {
  count: number | undefined;
  collectionId: string;
}

export const AssetsDrawerScreen = (props: Props) => {
  const wire = useWire();
  const { setShowUploadModal } = usePromptContext();
  const isConnecting = useIsIdentityConnecting();
  const collections = wire.services.collections;
  const collection = () => collections.getCollection(props.collectionId);

  const pageSignal = createSignal(1);
  const assetsListOptions = createSignal(createListResourceOptions(1));
  const page = createMemo(pageSignal[0]);
  let oldPage = page();
  createEffect(
    on(page, (page) => {
      if (oldPage === page) return;
      oldPage = page;
      startTransition(() => {
        assetsListOptions[1](createListResourceOptions(page));
      });
    }),
  );
  const resourceTrigger = createMemo(() => (isConnecting() ? undefined : collection()?.id));
  const [assets, { refetch }] = collections.resourceCollectionAssets(resourceTrigger, assetsListOptions[0]);

  const [isPolling, setIsPolling] = createSignal(false);
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let canPoll = false;

  const hasProcessingAssets = () =>
    parseAssetsListResponse(assets()).filter(
      (asset) =>
        readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Ready &&
        readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Failed,
    ).length > 0;

  const poll = () => {
    // Only proceed if we're allowed to poll and not already polling
    if (!canPoll || isPolling()) {
      return;
    }

    setIsPolling(true);

    if (hasProcessingAssets() && canPoll) {
      startTransition(() => {
        refetch();
      });

      timeout = globalThis.setTimeout(() => {
        setIsPolling(false);
        poll();
      }, 3_000);
    } else {
      // Stop polling if no processing assets
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
      setIsPolling(false);
    }
  };

  // Monitor for processing assets in initial data or after updates
  createEffect(() => {
    if (assets() && !timeout && hasProcessingAssets()) {
      poll();
    }
  });

  onMount(() => {
    canPoll = true;
    if (hasProcessingAssets()) {
      poll();
    }

    onCleanup(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      canPoll = false;
      setIsPolling(false);
    });
  });

  return (
    <div class={styles["assets-drawer"]}>
      <p class={styles["assets-drawer__title"]}>Assets you're chatting with:</p>

      <Suspense>
        <Show when={collection()}>
          <div class={styles["assets-drawer__section"]}>
            <Show
              when={(assets()?.data.result.entities ?? []).length > 0}
              fallback={
                <InfoPanel
                  icon={TbFolderFilled}
                  title={`0 assets in your ${formatCollectionName(collection()?.label)}.`}
                  variant="info"
                >
                  <PrimaryCTA
                    class={styles["assets-drawer__cta"]}
                    data-test-id="right-drawer-upload"
                    icon={RiSystemUploadCloud2Line}
                    label={`Add assets to your ${formatCollectionName(collection()?.label)}.`}
                    accessibleSuffix="to your current Collection."
                    onClick={() => {
                      stAnalytics.track("click_tracking", {
                        cta: "upload",
                        position: "right_drawer",
                        screen: getScreenType(),
                      });
                      setShowUploadModal(true);
                    }}
                  />
                </InfoPanel>
              }
            >
              <SectionHeader
                modifier="thin-bottom-padding"
                title={`${props.count === undefined ? "Assets" : `${props.count} ${pluralize(props.count, "asset")}`} in your ${formatCollectionName(collection()?.label)} and its sub-Collections.`}
                icon={TbFolderFilled}
              />
              <CollectionAssetsTable
                modifier="drawer"
                collectionName={collection()?.label || ""}
                data={assets()?.data.result.entities || []}
                collectionId={collection()?.id || ""}
              />

              <Show when={(assets()?.data.pagination?.totalPages || 0) > 1}>
                <Pagination
                  page={pageSignal}
                  totalPages={assets()?.data.pagination.totalPages}
                  totalCount={assets()?.data.pagination.count}
                />
              </Show>

              <SecondaryCTA
                class={styles["assets-drawer__cta"]}
                data-test-id="right-drawer-upload"
                icon={RiSystemUploadCloud2Line}
                label={`Add assets to your ${formatCollectionName(collection()?.label)}.`}
                accessibleSuffix="to your current Collection."
                onClick={() => {
                  stAnalytics.track("click_tracking", {
                    cta: "upload",
                    position: "right_drawer",
                    screen: getScreenType(),
                  });
                  setShowUploadModal(true);
                }}
              />
            </Show>
          </div>
        </Show>
      </Suspense>
      <p class={styles["assets-drawer__footer"]}>
        <Anchor
          data-test-id="storytile-docs-link"
          accessibleSuffix="Visit our documentation to"
          label="Learn more about assets and Collections."
          href="https://docs.storytell.ai/features/collections"
          target="_blank"
        />
      </p>
    </div>
  );
};
