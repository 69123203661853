import { type Accessor, batch, type Component, createSignal, onCleanup, onMount } from "solid-js";
import { TextSelectionMenu } from "../TextSelectionMenu";

// Handles selecting
export const TextUnitV1TextSelection: Component<{
  ref: Accessor<HTMLElement | undefined>;
  isThreadReadOnly: boolean;
}> = (props) => {
  const [selection, setSelection] = createSignal<Selection>();
  const [domRect, setDomRect] = createSignal<DOMRect>();

  const resetPanel = () => {
    batch(() => {
      setSelection();
      setDomRect();
    });
  };

  // The text selection menu should only pop up after a mouse up event or touch end event
  // to prevent the modal from showing up while the user is still selecting
  // ENG-1469: https://linear.app/storytell/issue/ENG-1469/buggy-experience-when-highlighting-a-response-action-hover-menu-pops
  // ENG-1539: https://linear.app/storytell/issue/ENG-1539/text-selection-menu-reappears-after-clicking-on-an-item
  const onSelectionEnd = () => {
    const selection = getSelection();
    if (!selection) return;

    batch(() => {
      if (!selection) return resetPanel();
      if (!props.ref()?.contains(selection.anchorNode) || !props.ref()?.contains(selection.focusNode))
        return resetPanel();
      if (selection.rangeCount === 0 || selection.isCollapsed) return resetPanel();

      setSelection(selection);
      setDomRect(selection.getRangeAt(0).getBoundingClientRect());
    });
  };

  onMount(() => {
    document.addEventListener("mouseup", onSelectionEnd);
    document.addEventListener("touchend", onSelectionEnd);
    onCleanup(() => {
      document.removeEventListener("mouseup", onSelectionEnd);
      document.removeEventListener("touchend", onSelectionEnd);
    });
  });

  return (
    <TextSelectionMenu
      isThreadReadOnly={props.isThreadReadOnly}
      rect={domRect()}
      from="select"
      getText={() => selection()?.toString() || ""}
      getEl={() => selection()?.anchorNode as HTMLElement | undefined}
    />
  );
};
