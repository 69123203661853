import { createEffect } from "solid-js";
import { useWire } from "@core/wire";
import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";

/**
 * Handles auto-submitting a campaign's prompt after a user has uploaded a file
 * Look for `getThreadAutoSubmitCampaignPrompt` for the actual logic that drives the decision
 */
export const useAutoSubmitFileCampaign = () => {
  const wire = useWire();
  const { submitPrompt } = usePromptContext();

  let lastSubmittedForThread: null | string = null;

  createEffect(() => {
    const { computed, threadId } = wire.services.threads.snapshot.context;

    // If we already auto-submitted for this thread then don't do it again
    if (lastSubmittedForThread === threadId) return;
    if (!computed.autosubmitCampaignPrompt) return;

    lastSubmittedForThread = threadId;
    submitPrompt({
      text: computed.autosubmitCampaignPrompt,
      mentionedAssets: [],
    });
  });
};
