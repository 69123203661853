import { stAnalytics } from "@repo/analytics";
import type { curator } from "@repo/client";
import { Motion } from "@repo/solid-motionone";
import { pointerHover } from "@solid-primitives/pointer";
import { type Component, For, Match, Show, Switch, createMemo, createSignal } from "solid-js";
import { twMerge } from "tailwind-merge";
import { CircularAvatar, CircularAvatarSize } from "@core/components/_original/CircularAvatar";
import { CopyButton } from "@core/components/_original/CopyButton";
import { PromptCollectionCTA } from "@core/components/cta/PromptCollectionCTA";
import { useBreakpoints } from "@core/lib/useBreakPoints";
import { useWire } from "@core/wire";
import { truncatePromptArray } from "./truncatePromptArray";
import { StIcon } from "@core/components/icons";
import { TbFile } from "solid-icons/tb";
import { parsePromptTextMentions } from "@core/lib/parsePromptTextMentions";

// place this in code to avoid being tree-shaken
pointerHover;

// TODO @andi make new component for v2
export type TextInstructionUnitV1Props = {
  message: curator.MessagePromptV2;
  class?: string;
};

export const TextPromptUnitV1 = (props: TextInstructionUnitV1Props) => {
  const MAX_LEN = 340;
  const [ref, setRef] = createSignal<HTMLSpanElement>();
  const breakpoints = useBreakpoints();
  const wire = useWire();

  const [hovering, setHovering] = createSignal(false);
  const [seeAll, setSeeAll] = createSignal(false);

  // Collection mentions are sent to the server as [collection_id]
  // We need to replace them with the collection label when displaying the prompt in the thread
  const formatPrompt = createMemo(() => {
    const prompt = props.message.prompt;
    return parsePromptTextMentions(prompt);
  });

  return (
    <div
      class={`relative mb-6 ${!truncatePromptArray(formatPrompt(), MAX_LEN).hasTruncated ? "pb-2 flex items-center" : "pb-0"}`}
      use:pointerHover={setHovering}
    >
      <div class="absolute -top-2 md:-top-3 left-[-2.8125rem] md:left-[-4.6875rem] select-none pointer-events-none">
        <CircularAvatar
          userId={props.message.createdBy}
          size={breakpoints.md ? CircularAvatarSize.Default : CircularAvatarSize.Mini}
          fullName="Guest"
          class="ring-background-decoration"
        />
      </div>
      <Motion.div
        // {...getUnitAnimationConfig()}
        class={twMerge("w-full relative dark:text-white md:pb-2 pl-2 md:pl-0 pr-2", props.class)}
      >
        <p
          ref={setRef}
          class="relative r-left-12 text-lg md:text-xl h-full leading-normal font-medium text-black dark:text-white"
        >
          <For each={seeAll() ? formatPrompt() : truncatePromptArray(formatPrompt(), MAX_LEN).prompt}>
            {(segment) => (
              <Switch>
                <Match when={segment.type === "text" && segment.text}>{(s) => <span>{s()}</span>}</Match>
                <Match when={segment.type === "collection" && segment}>
                  {(s) => (
                    <PromptCollectionCTA
                      collectionId={s().id}
                      accessiblePrefix="Show assets in"
                      data-test-id={`title-collection-link-${s().name.replace(" ", "-").toLowerCase()}`}
                      label={s().name}
                    />
                  )}
                </Match>
                <Match when={segment.type === "asset" && segment}>
                  {(s) => <PromptAssetCTA id={s().id} name={s().name} />}
                </Match>
              </Switch>
            )}
          </For>

          <Show when={truncatePromptArray(formatPrompt(), MAX_LEN).hasTruncated}>
            <button
              type="button"
              onClick={() => setSeeAll((s) => !s)}
              class="text-sm leading-normal text-violet-500 dark:text-violet-400 selection:text-white ml-1 select-none"
            >
              {seeAll() ? "See less" : "See more"}
            </button>
          </Show>
        </p>

        <Show when={hovering()}>
          <div class="absolute bottom-1 right-1 flex items-center gap-1">
            <CopyButton
              label="Copy Prompt"
              onClick={() => {
                stAnalytics.track("thread_prompt_copied", undefined);
              }}
              content={formatPrompt()
                .map((segment) => ("text" in segment ? segment.text : `@[${segment.id}]"${segment.name}"`))
                .join("")}
            />
          </div>
        </Show>
      </Motion.div>
    </div>
  );
};

const PromptAssetCTA: Component<{ id: string; name: string }> = (props) => {
  return (
    <span class="bg-secondary text-on-secondary relative top-0.5 px-2 rounded inline-flex items-center gap-1">
      <StIcon icon={TbFile} />
      <span>{props.name}</span>
    </span>
  );
};
