import { DropdownMenu } from "@kobalte/core";
import { TbChevronDown, TbMaximize, TbMinimize, TbMinus, TbPlus, TbTriangleFilled } from "solid-icons/tb";
import { For, type ParentComponent, Show } from "solid-js";
import { twMerge } from "tailwind-merge";
import { Icon, type IconName, IconsSize, StIcon } from "@core/components/icons";

export const ThreadTag: ParentComponent<{
  toggleMinimize: () => void;
  isMinimized: boolean;
  muted?: boolean;
  menuItems?: { label: string; onClick: () => void; icon: IconName }[];
}> = (props) => {
  return (
    <div class="relative flex items-center gap-4 left-[-2rem] md:left-[-3.5rem] mt-4 mb-2">
      <button
        data-muted={props.muted}
        type="button"
        onClick={props.toggleMinimize}
        class="w-5 h-5 min-w-max min-h-max grid place-content-center rounded bg-primary data-[muted=true]:bg-background-decoration"
      >
        <StIcon
          data-minimized={props.isMinimized}
          icon={TbTriangleFilled}
          class={twMerge(
            "size-2 data-[minimized=true]:rotate-90 data-[minimized=false]:rotate-180 transition-transform",
            !props.muted && "[&_path]:text-on-primary",
          )}
        />
      </button>
      <button
        onClick={props.toggleMinimize}
        type="button"
        data-muted={props.muted}
        class="w-auto text-[10px] text-primary font-semibold data-[muted=true]:text-on-background-alternate uppercase tracking-wider min-w-max min-h-max"
      >
        {props.children}
      </button>
    </div>
  );
};
