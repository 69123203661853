import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";
import { type Component, onMount } from "solid-js";

export const EmptyThreadMessage: Component = () => {
  const { editor, setShowUploadModal } = usePromptContext();
  onMount(() => {
    editor()?.commands.focus();
  });

  return (
    <div class="px-20 py-20 flex flex-col justify-center items-center text-center gap-4 max-w-thread mx-auto dark:text-white">
      <h2 class="font-bold text-4xl leading-normal">What do you want to accomplish?</h2>
      <p class="text-lg leading-normal">
        Start a new thread by submitting a prompt or by{" "}
        <button
          type="button"
          class="underline underline-offset-2 leading-normal text-lg w-auto"
          onClick={() => {
            setShowUploadModal(true);
          }}
        >
          uploading a file
        </button>
      </p>
    </div>
  );
};
