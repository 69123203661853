import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";
import type { CampaingPageDataPrompt } from "@core/marketing/useUseCasesData";
import { type Component, onMount, Show } from "solid-js";
import { MarkdownRenderer } from "@core/domains/threads/components/units/MarkdownRenderer";

export const PredefinedPromptDetails: Component<{
  prompt?: CampaingPageDataPrompt;
}> = (props) => {
  const { editor } = usePromptContext();
  onMount(() => {
    editor()?.commands.focus();
  });

  return (
    <div class="px-20 py-20 flex flex-col justify-center items-center text-center gap-8 max-w-thread mx-auto dark:text-white">
      <h2 class="font-bold text-4xl leading-normal">{props.prompt?.prompt.name}</h2>

      <MarkdownRenderer highlight={false} md={props.prompt?.prompt.summary || ""} prefix="" />

      <Show when={props.prompt?.prompt.seoContent}>
        <section class="text-left">
          <MarkdownRenderer highlight={false} md={props.prompt?.prompt.seoContent || ""} prefix="" />
        </section>
      </Show>
    </div>
  );
};
