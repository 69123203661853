import type { curator } from "@repo/client";
import { Motion } from "@repo/solid-motionone";
import { debounce } from "@solid-primitives/scheduled";
import { TbWorldOff, TbWorldPlus } from "solid-icons/tb";
import { type Component, For, Match, Show, Switch, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import type { DOMElement } from "solid-js/jsx-runtime";
import { twMerge } from "tailwind-merge";
import { IconLabel } from "@core/components/IconLabel";
import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";
import { useWire } from "@core/wire";
import { TextSelectionMenu } from "../TextSelectionMenu";
import "./codeHighlightTheme.css";
import { MarkdownRenderer } from "./MarkdownRenderer";
import { TextUnitStoryTiles } from "./TextUnitStoryTiles";
import { TextUnitV1ActionsBar } from "./TextUnitV1ActionsBar";
import { TextUnitV1TextSelection } from "./TextUnitV1TextSelection";

export type TextInstructionUnitV1Props = {
  message: curator.MessageTextV1;
  class?: string;
  disableActions?: boolean;
  isThreadReadOnly: boolean;
} & {
  promptMessageId: string;
};

const citationRefsCache: Record<string, curator.Citation | undefined> = {};

export const TextUnitV1 = (props: TextInstructionUnitV1Props) => {
  const { editor, typePrompt } = usePromptContext();
  const wire = useWire();
  const [ref, setRef] = createSignal<HTMLElement>();

  const [tiles, setTiles] = createSignal<curator.Citation[]>([]);

  const trigger = async () => {
    const refs = props.message.provenance.scoped?.citations;
    // Make sure to populate the cache with the citations from the current thread in case they're already loaded
    Object.values(wire.services.threads.snapshot.context.knowledgeProvenance.citations).forEach((c) => {
      citationRefsCache[c.citationId] = c;
    });

    // If any of the citation refs are not in the cache, then fetch the thread to get the refs
    if (refs?.some((r) => !citationRefsCache[r])) {
      const result = await wire.services.threads.loadThread(props.message.threadId);

      const tiles = refs
        .map((r) => {
          const found = Object.keys(result.data.knowledgeProvenance?.citations).find((c) => c === r);
          if (!found) return;
          return result.data.knowledgeProvenance?.citations[found];
        })
        .filter((r) => r !== undefined) as curator.Citation[];

      refs.forEach((r) => {
        citationRefsCache[r] = result.data.knowledgeProvenance.citations?.[r];
      });
      setTiles([...tiles]);
      return;
    }

    if (refs && citationRefsCache) {
      setTiles(refs.map((r) => (r ? citationRefsCache[r] : null)).filter((r): r is NonNullable<typeof r> => r != null));
    } else {
      setTiles([]);
    }
  };

  createEffect(() => {
    const refs = props.message.provenance.scoped?.citations;
    if (refs?.length || props.message.isDone) {
      trigger();
    }

    // Hacky workaround for some race condition in the BE where the message is done but the citations are empty when fetching the thread
    // It's ok to call it a couple of extra times since the trigger function won't refetch if we already have the citations
    if (props.message.isDone) {
      setTimeout(() => {
        trigger();
      }, 2000);
      setTimeout(() => {
        trigger();
      }, 8000);
    }
  });

  return (
    <Motion.div
      // {...getUnitAnimationConfig()}
      class="dark:text-slate-200 text-gray-800 text-sm md:text-base scroll-mt-32"
      data-block={props.message.messageId}
    >
      <div ref={setRef} class={twMerge("flex flex-col break-words min-h-[32px]", props.class)}>
        <TextUnitV1Markdown isThreadReadOnly={props.isThreadReadOnly} message={props.message} />
      </div>

      <TextUnitV1TextSelection isThreadReadOnly={props.isThreadReadOnly} ref={ref} />
      <Switch>
        <Match
          when={
            props.message?.provenance?.scoped?.worldKnowledge &&
            !props.message.provenance.scoped.collectionReferenceIDs?.length &&
            !props.message.provenance.scoped.collectionAssets?.length &&
            !props.message.provenance.scoped.explicitAssets?.length
          }
        >
          <IconLabel
            class="select-none"
            icon={TbWorldPlus}
            label="Includes just public knowledge."
            modifiers={["italic", "block"]}
          />
        </Match>
        <Match when={props.message?.provenance?.scoped?.worldKnowledge}>
          <IconLabel
            class="select-none"
            icon={TbWorldPlus}
            label="Includes public knowledge."
            modifiers={["italic", "block"]}
          />
        </Match>
        <Match when={!props.message?.provenance?.scoped?.worldKnowledge}>
          <IconLabel
            class="select-none"
            icon={TbWorldOff}
            label="Excludes public knowledge."
            modifiers={["italic", "block"]}
          />
        </Match>
      </Switch>
      <Show when={!props.disableActions}>
        <Show when={tiles().length}>
          <TextUnitStoryTiles tiles={tiles()} />
        </Show>

        <TextUnitV1ActionsBar
          promptMessageId={props.promptMessageId}
          message={props.message}
          ref={ref}
          isThreadReadOnly={props.isThreadReadOnly}
        />

        <Show when={props.message.textSuggestions}>
          <div>
            <span class="text-[0.625rem] text-violet-500 dark:text-violet-400 underline underline-offset-4 uppercase tracking-wider">
              Prompt Suggestions:
            </span>
            <ul class="block list-disc ml-4 pt-2">
              <For each={props.message.textSuggestions}>
                {(suggestion) => (
                  <li class="list-item list-disc list-outside dark:text-purple-400 text-black pl-2 text-left">
                    <button
                      class="inline-block w-auto text-left hover:underline underline-offset-2 leading-normal text-base min-h-max"
                      type={"button"}
                      onClick={() => {
                        editor()?.commands.focus();
                        typePrompt(suggestion, { highlight: true });
                      }}
                    >
                      {suggestion}
                    </button>
                  </li>
                )}
              </For>
            </ul>
          </div>
        </Show>
      </Show>
    </Motion.div>
  );
};

const TextUnitV1Markdown: Component<{
  message: curator.MessageTextV1;
  isThreadReadOnly: boolean;
}> = (props) => {
  const [el, setEl] = createSignal<HTMLElement>();
  const [domRect, setDomRect] = createSignal<DOMRect>();
  const [range, setRange] = createSignal<Range>();

  // Use prevent hide to keep the dropdown alive when a user
  // exits all markdown elements but is hovering the mouse on the dropdown
  let preventHide = false;
  // Used to block the dropdown from showing when the text selction dropdown is out
  let preventShow = false;

  // Debouncing it to prevent flickering when switching in between elements fast
  const setRectDebounced = debounce((value: DOMRect | undefined) => setDomRect(value), 500);

  // The hover text modal is different from
  onMount(() => {
    const listener = () => {
      const selection = getSelection();
      if (selection?.rangeCount && selection?.rangeCount > 0) {
        setRange(selection?.getRangeAt(0));
      }
      if ((selection?.toString() || "") !== "") {
        setRectDebounced.clear();
        setDomRect();
        preventShow = true;
      } else {
        preventShow = false;
      }
    };
    const focusOut = (event: FocusEvent) => {
      const _range = range();
      if (_range && !_range?.collapsed) {
        if (event.relatedTarget instanceof HTMLElement && event.relatedTarget.id.includes("dropdownmenu")) {
          getSelection()?.removeAllRanges();
          getSelection()?.addRange(_range);
        }
      }
    };
    document.addEventListener("selectionchange", listener);
    document.addEventListener("focusout", focusOut);
    onCleanup(() => {
      document.removeEventListener("selectionchange", listener);
      document.removeEventListener("focusout", focusOut);
    });
  });

  const onHover = (
    e: MouseEvent & {
      target: DOMElement;
    },
  ) => {
    if (preventShow) return;
    if (!(e.target instanceof HTMLElement)) return;
    setEl(e.target);
    setRectDebounced.clear();
    const elRect = e.target.getBoundingClientRect();

    const messageEl = document.querySelector(`[data-block="${props.message.messageId}"]`);
    const messageRect = messageEl?.getBoundingClientRect() ?? elRect;

    setRectDebounced({
      top: elRect.top,
      height: elRect.height,
      bottom: elRect.bottom,
      y: elRect.y,
      left: messageRect.left,
      right: messageRect.right,
      width: messageRect.width,
      x: messageRect.x,
      toJSON: elRect.toJSON,
    });
  };

  const onUnhover = () => {
    if (preventHide) return;
    setRectDebounced(undefined);
  };

  return (
    <>
      <TextSelectionMenu
        isThreadReadOnly={props.isThreadReadOnly}
        rect={domRect()}
        from="block"
        animatedMovement
        onClose={() => {
          preventHide = false;
          setRectDebounced.clear();
          setDomRect();
        }}
        opts={{
          preventScroll: false,
          placement: "right-start",
          hideWhenDetached: true,
        }}
        content={{
          onMouseEnter: () => {
            preventHide = true;
            setRectDebounced.clear();
          },
          onMouseLeave: (e) => {
            if ("toElement" in e && e.toElement instanceof HTMLElement) {
              const id = e.toElement.id;
              if (!id.includes("dropdownmenu")) {
                preventShow = true;
                preventHide = false;
                onUnhover();
                setTimeout(() => {
                  preventShow = false;
                }, 510);
                return;
              }
            }

            preventHide = false;
            onUnhover();
          },
        }}
        getText={() => el()?.innerText || ""}
        getEl={el}
      />

      <MarkdownRenderer
        prefix={props.message.messageId}
        md={props.message.parts.join("\n")}
        onMouseOverChild={onHover}
        onMouseOutChild={onUnhover}
      />
    </>
  );
};
