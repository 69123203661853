import { stAnalytics } from "@repo/analytics";
import { getRequestClient, type assets as assetsNS } from "@repo/client";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { TbArchive, TbMessage2Plus } from "solid-icons/tb";
import { type Accessor, type Resource, Show, Suspense, createEffect, createResource, createSignal } from "solid-js";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";
import { useCreateNewSmartchatInCollection } from "@core/domains/threads/hooks/useCreateNewSmartchatInCollection";
import type { ThreadGroup, ThreadUnit } from "@core/domains/threads/screens/ThreadScreen";
import { pluralize } from "@core/lib/string/pluralize";
import { getScreenType } from "@core/lib/ui/getScreenType";
import { AssetsDrawerScreen } from "@core/screens/drawer/AssetsDrawerScreen";
import { useUIState } from "@core/ui/UIState";
import { useWire } from "@core/wire";
import styles from "./ChatTop.module.css";
import { CollectionBreadcrumbs } from "./CollectionBreadcrumbs";
import { StIcon } from "./icons";

interface Props {
  messages: Accessor<(ThreadGroup | ThreadUnit)[]>;
  breadcrumbs: Breadcrumb[];
}

export const ChatTop = (props: Props) => {
  const state = useUIState();
  const [open, setOpen] = state.rightDrawer;
  const [, setRightDrawerContents] = state.rightDrawerContents;
  const { activeCollection, setShowUploadModal } = usePromptContext();
  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => activeCollection()?.id);

  const wire = useWire();
  const [assetCount] = createResource(
    () => activeCollection()?.id,
    async (id) => {
      const client = getRequestClient(wire.services.auth.token);
      const response = await client.controlplane.GetCountAssetsByCollectionID(id, {
        includeSubCollections: true,
      });
      return response.data;
    },
  );

  return (
    <>
      <div class={styles["thread-top"]}>
        <div class={styles["thread-top__left"]}>
          <CollectionBreadcrumbs inline max={3} breadcrumbs={props.breadcrumbs || []} isChat />
        </div>
        <div class={styles["thread-top__right"]}>
          <Suspense fallback={<span class="screen-reader">0 assets</span>}>
            <Show when={assetCount()} fallback={<span class="screen-reader">0 assets</span>}>
              <SecondaryCTA
                label={`${assetCount()} assets in scope`}
                data-test-id="thread-top-upload"
                icon={TbArchive}
                onClick={() => {
                  setRightDrawerContents(() => () => (
                    <AssetsDrawerScreen count={assetCount()} collectionId={activeCollection()?.id || ""} />
                  ));
                  setOpen(true);
                  stAnalytics.track("click_tracking", {
                    cta: "show_chat_assets_drawer",
                    position: "chat_header",
                    screen: getScreenType(),
                  });
                }}
                accessibleSuffix="to this chat's Collection."
                inline
              />
              <span class={styles["thread-top__divider"]}>|</span>
            </Show>
          </Suspense>
          <SecondaryCTA
            label="Add asset"
            data-test-id="thread-top-upload"
            icon={RiSystemUploadCloud2Line}
            onClick={() => {
              setShowUploadModal(true);
              stAnalytics.track("click_tracking", {
                cta: "upload",
                position: "chat_header",
                screen: getScreenType(),
              });
            }}
            accessibleSuffix="to this chat's Collection."
            inline
          />
          <Show when={props.messages().length > 0}>
            <span class={styles["thread-top__divider"]}>|</span>
            <SecondaryCTA
              label="Start new chat"
              data-test-id="thread-top-create-chat"
              icon={TbMessage2Plus}
              onClick={() => {
                onCreateSmartchat();
                stAnalytics.track("click_tracking", {
                  cta: "create_chat",
                  position: "chat_header",
                  screen: getScreenType(),
                });
              }}
              accessibleSuffix="in your current Collection."
              inline
            />
          </Show>
        </div>
      </div>
    </>
  );
};
