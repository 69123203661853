import type { IconTypes } from "solid-icons";
import { CustomSolidIcon } from "./CustomSolidIcon";

export const StBrandX: IconTypes = (props) =>
  CustomSolidIcon(
    {
      a: {
        viewBox: "0 0 24 24",
        stroke: "currentColor",
        fill: "none",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
      },
      c: '<path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />',
    },
    props,
  );
